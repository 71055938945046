import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';

type TCompany = {
  name: string;
  slogan: string;
  logo: string;
};

type TLimit = {
  search: number;
  book: number;
};

type TTokens = {
  token: string;
  refreshToken: string;
};

type TUser = {
  company: TCompany;
  limit?: TLimit;
  _id?: string;
  username: string;
  CID: string;
  balance: number;
  avatar: string;
  address?: string;
  email: string;
  phone: string;
  role: string;
  status?: string;
  adminEdited?: boolean;
  lastEditedbyAdmin?: string;
  api?: boolean;
  airControl?: boolean;
  createdAt?: string;
  updatedAt?: string;
  showGross?: boolean;
  showNet?: boolean;
  tokens: TTokens;
  error?: null | unknown;
  userStatus?: string;
  resStatus?: string;
};

type TUserDataState = {
  isLogged: boolean;
  user: TUser | null;
  isLoading: boolean;
  isSuccess: boolean;
  token: string | null;
  refreshToken: string | null;
  error: boolean;
  errorMessage: string | null;
  canAccessResource: boolean;
};

const initialState: TUserDataState = {
  isLogged: false,
  user: null,
  isLoading: false,
  isSuccess: false,
  token: null,
  refreshToken: null,
  error: false,
  errorMessage: null,
  canAccessResource: false,
};

const userDataSlice = createSlice({
  name: 'userData',
  initialState,
  reducers: {
    addUserData: (state, action: PayloadAction<TUser>) => {
      const { tokens } = action.payload;
      state.isLogged = true;
      state.user = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
      state.token = tokens.token;
      state.refreshToken = tokens.refreshToken;
      state.error = false;
      state.errorMessage = null;
      state.canAccessResource = true;

      Cookies.set('jwt', tokens.token, { expires: 7 });
      Cookies.set('refreshJwt', tokens.refreshToken, { expires: 7 });
    },
    updateUserBalance: (state, action: PayloadAction<number>) => {
      if (state.user) {
        state.user.balance = action.payload;
      }
    },
    removeUserData: (state) => {
      state.isLogged = false;
      state.user = null;
      state.isLoading = false;
      state.token = null;
      state.refreshToken = null;
      state.error = false;
      state.errorMessage = null;
      state.canAccessResource = true;

      Cookies.remove('jwt');
      Cookies.remove('refreshJwt');
    },
  },
});

export const { addUserData, removeUserData, updateUserBalance } =
  userDataSlice.actions;
export default userDataSlice.reducer;
